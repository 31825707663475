import { useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "../components/AppContext";
import { ViewportContext } from "../components/ViewportSizeContext";
import { mapValues } from "../utils/utils";
import { useInView } from "react-intersection-observer";

export function useLottieScroll(
  elRef,
  playerRef,
  offsetPercentage,
  offsetHigh1
) {
  // A hook that takes a ref to an element, a ref to a player and an
  // optional offset, and manipulates the playhead of the animation
  // depending on the positon of the element in the viewport

  const loopRef = useRef();
  const rafRef = useRef();

  loopRef.current = () => {
    if (playerRef.current && playerRef.current.state) {
      const animInstance = playerRef.current.state.instance;

      const { height, top } = elRef.current.getBoundingClientRect();

      const offset = (window.innerHeight / 100) * offsetPercentage;

      const value = top;
      const low1 = window.innerHeight - offset;
      const high1 = offsetHigh1
        ? window.innerHeight - (height + offset)
        : window.innerHeight - height;
      const low2 = 0;
      const high2 = animInstance.totalFrames - 1;

      const target = mapValues(value, low1, high1, low2, high2);

      animInstance.goToAndStop(target, true);

      rafRef.current = requestAnimationFrame(loopRef.current);
    }
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);
}

export function useEnteredViewport(threshold) {
  // A hook for when you want to trigger an action the first time an
  // element enters the viewport
  const { ref, inView } = useInView({
    threshold: threshold,
  });
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    if (inView) {
      setEntered(true);
    }
  }, [inView]);

  return { ref, entered };
}

export function useViewportSize() {
  const { isDesktop, innerHeight } = useContext(ViewportContext);
  return {
    isDesktop,
    innerHeight,
  };
}

// If we land on the page and the menu is not in a transitional state, ie
// we navigate directly to the page, the we want to kick of content-entry
// animations right away. However, if we are arriving on the page via the menu
// then we should wait for the menus transition-out animation to finish
export function useStartAnim() {
  const { menuTransitioning } = useContext(AppContext);
  const [startAnim, setStartAnim] = useState(!menuTransitioning);

  useEffect(() => {
    if (!menuTransitioning) setStartAnim(true);
  }, [menuTransitioning]);

  return startAnim;
}
