export function mapValues(value, low1, high1, low2, high2) {
  var val = low2 + ((value - low1) * (high2 - low2)) / (high1 - low1);
  if (high2 > low2) {
    if (val > high2) {
      val = high2;
    }
    if (val < low2) {
      val = low2;
    }
  } else {
    if (val < high2) {
      val = high2;
    }
    if (val > low2) {
      val = low2;
    }
  }
  return val;
}
