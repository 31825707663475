import Box from "./Box";

function BoxWithGutter({ children, ...props }) {
  return (
    <Box {...props} px={[23, 50, 70, null]}>
      {children}
    </Box>
  );
}

export default BoxWithGutter;
