export const SMART_GRIDS = "smart-grids";
export const DISTRIBUTED_ENERGY = "distributed-energy";
export const ENERGY_MGMT = "energy-mgmt";
export const DIGITAL_INNOVATION = "digital-innovation";
export const CERTIFICATES = "certificates";
export const AUTOMOTIVE_BUSINESSES = "automotive-businesses";
export const ENERGY_INTENSIVE = "energy-intensive";
export const EXTRACTIVE_INDUSTRIES = "extractive-industries";
export const IT_SERVICES_AND_DATA = "it-services-and-data";
export const UTILITIES = "utilities";
export const TRANSPORT_AND_LOGISTICS = "transport-and-logistics";
export const PUBLIC_AND_COMMERCIAL_BUILDINGS =
  "public-and-commercial-buildings";
export const PHARMA = "pharma";
export const FMCG = "fmcg";
export const PPAS = "ppas";
export const CONSUMPTION_ORIENTED = "consumption-oriented";
export const UP = "up";
export const DOWN = "down";
export const LEFT = "left";
export const RIGHT = "right";
export const menuTransitionDuration = 500;
export const SHARING_IMAGE_FILTER_SPEC = "fill-1200x675-c0";
export const ANIMATION_STAGGER_AMOUNT = 200;
export const SECONDARY_ANIMATION_STAGGER_AMOUNT = ANIMATION_STAGGER_AMOUNT * 2;
