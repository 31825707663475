import { createContext, useEffect, useState } from "react";

import { menuTransitionDuration } from "../core/constants";
import { useRouter } from "next/router";

export const AppContext = createContext({});

function AppContextProvider({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTransitioning, setMenuTransitioning] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setMenuOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    if (menuOpen) {
      setMenuTransitioning(true);
    } else {
      setTimeout(() => {
        setMenuTransitioning(false);
      }, menuTransitionDuration);
    }
  }, [menuOpen]);

  useEffect(() => {
    menuOpen
      ? document.body.classList.add("menu-open")
      : document.body.classList.remove("menu-open");
  }, [menuOpen]);

  return (
    <AppContext.Provider value={{ menuOpen, setMenuOpen, menuTransitioning }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
