import { createContext, useEffect, useRef, useState } from "react";

export const ViewportContext = createContext({});

function ViewportSizeProvider({ children }) {
  const breakpoint = 960;
  const [isDesktop, setIsDesktop] = useState(true);
  const [innerHeight, setInnerHeight] = useState(1000);
  const widthRef = useRef(null);

  useEffect(() => {
    setIsDesktop(window.innerWidth >= breakpoint);
    setInnerHeight(window.innerHeight);
    widthRef.current = window.innerWidth;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= breakpoint);
      // Check if width has also changed to know if window has truly resized,
      // to prevent the resize happening on iOS scroll
      if (window.innerWidth !== widthRef.current) {
        setInnerHeight(window.innerHeight);
        widthRef.current = window.innerWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ isDesktop, innerHeight }}>
      {children}
    </ViewportContext.Provider>
  );
}

export default ViewportSizeProvider;
